<template>
  <div>
    <div class="pageHomeViewByCourseCarousel" :id="`accordion-${category.id}`"  v-for="(category, index) in getAllCategories" :key="category.id">
      <div class="pageHomeViewByCourseCarousel__header">
        <CommonTitleCourse :value="category.title">
          <template v-slot:controls> 
            <CommonControlsCarousel  @prev="prev(uniqueId + category.title + category.id)"
              @next="next(uniqueId + category.title + category.id)" />
          </template>
        </CommonTitleCourse>
      </div>

      <div class="pageHomeViewByCourseCarousel__container">
        <div class="pageHomeViewByCourseCarousel__container--box">
         
          <Swiper :ref="`${uniqueId + category.title + category.id}`" :options="swiperOption">
            <!-- Loading -->
            <template v-if="loading">
              <SwiperSlide v-for="i in 6" :key="i">
                <CommonSkeletonsCover />
              </SwiperSlide>
            </template>

            <!-- Carousel View -->
            <template v-else>

              <template>
                <SwiperSlide v-for="item in coursesByCategory(category.id)" :key="item.id">
                  <div>
                    <CommonCardsCourse :data="item" @show="$router.push({ path: 'curso/' + item.id })"
                      @edit="$router.push({ path: 'config_curso/' + item.id })" />
                    <CommonBoxCourse :data="item" />
                  </div>
                </SwiperSlide>
              </template>
            </template>
          </Swiper>
        </div>
      </div>
    </div>

    <infinite-loading @infinite="infiniteHandler" v-if="courses.length > 0">
      <div slot="no-results"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
      <div slot="error"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    this.uniqueId = this.createUniqueId();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      getModeCarousel: "config/getModeCarousel",
      getIsAdmin: "config/getIsAdmin",
      getMovieCoverMode: "config/getMovieCoverMode",
      getRearrangeCourse: "home/getRearrangeCourse",
      getCoursesList: "home/getCoursesList",
      getAllCategories: "home/getAllCategories",
      getIsMobile: "layout/getIsMobile",
      getHideDisabledCourses: "config/getHideDisabledCourses",
    }),
    courses: {
      get() {
        if (this.getHideDisabledCourses) {
          return this.getCoursesList.filter(item => item?.has_access);
        } else {
          return this.getCoursesList;
        }

      },
    },
  },
  components: {
    CommonTitleCourse: require("@/components/common/title/Course.vue").default,
    CommonBoxCourse: require("@/components/common/box/Course.vue").default,
    CommonCardsCourse: require("@/components/common/cards/Course.vue").default,
    CommonSkeletonsCover: require("@/components/common/skeletons/Cover.vue")
      .default,
    CommonControlsCarousel:
      require("@/components/common/carousel/ControlsCarousel.vue").default,
    InfiniteLoading,
  },
  props: {
    loading: {
      type: Boolean,
    },
    controlRef: {
      type: String,
    },
    data: {
      type: [Object],
      default: () => [{}],
    },
    poster: {
      type: Boolean,
    },
    showRating: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
      actionFetchHomeData: "home/actionFetchHomeData",
    }),
    async loadData(params) {
      await this.actionFetchHomeData(params);
      const courses = [...this.courses];
      const dataCourses = this.organizedData(courses);
      this.general = [dataCourses];
    },
    async infiniteHandler($state) {
      if (this.page === this.lastPage) {
        $state.complete();
        return;
      }
      this.page += 1;
      try {
        await this.loadData({ page: this.page })
        $state.loaded();
      } catch (error) {
        $state.error();
      }
    },
    coursesByCategory(category) {
      return this.courses.filter((item) => item?.category?.id == category);
    },
    async loadMore() {
      this.page += 1;
      await this.actionFetchHomeData({ page: this.page })
    },
    allowCourse(item) {
      return (
        this.data?.is_liberated === item ||
        (this.data?.is_liberated !== item && item)
      );
    },
    async denyCourse(item) {
      const response = await this.actionFetchCourseAvailability(item?.id);

      const fragment = {
        type: (await response?.liberated_at) ? "module" : "course",
        allow:
          (await response?.is_liberated) === false ||
            item.is_liberated === false
            ? false
            : true,
        date:
          item.liberated_at !== null
            ? item.liberated_at
            : await response?.liberated_at,
      };

      const data = {
        ...fragment,
        type: fragment.type === "course" ? 1 : 4,
      };

      if (await response?.has_access === false) {
        this.$emit("showModalBuyCourse", response);
      } else {
        this.$emit("show", data);
      }


    },
    createUniqueId() {
      const timestamp = new Date().getTime();
      const randomNumber = Math.floor(Math.random() * 10000);
      const uniqueId = `${timestamp}_${randomNumber}`;
      return uniqueId;
    },
    prev(name) {
      this.$refs[name].at(0).$swiper.slidePrev();
    },
    next(name) {
      this.$refs[name].at(0).$swiper.slideNext();
    }
  },
  data() {
    return {
      uniqueId: null,
      page: 1,
      loadingMore: false,
      swiperOption: {
        direction: "horizontal",
        loop: false,
        height: 60,
        spaceBetween: 24,
        slidesPerView: "auto",
        centeredSlides: false,
        resizeReInit: true,
        watchOverflow: true,
        on: {
          reachEnd: () => this.loadMore()
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.pageHomeViewByCourseCarousel {
  position: relative;
  margin-bottom: 64px;

  &__container {
    position: relative;
    max-width: 100%;
    overflow: hidden;

    &--box {
      position: relative;
    }
  }

  .swiper-slide {
    position: relative;
    max-width: 264px;
    min-height: 92px;
    border-radius: var(--default-radius);
    overflow: hidden;

    @media screen and (max-width: 820px) {
      max-width: 264px;
    }

    @media screen and (max-width: 768px) {
      max-width: 264px;
    }

    @media screen and (max-width: 480px) {
      max-width: calc(100% / 2);
    }

    @media screen and (max-width: 360px) {
      ::v-deep .commonCardsCover__box {
        max-width: 264px;
      }
    }

    @media screen and (max-width: 310px) {
      max-width: 264px;
    }
  }

  .swiper-wrapper {
    display: flex;
    gap: 24px;
  }
}
</style>
